import React, { createContext, useContext, useState } from 'react';
import ErrorNotification from './ErrorNotification';

const ErrorContext = createContext();

export const useContextError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState({});

    const showError = (message) => {
        const timestamp = new Date();
        setErrorMessage({ message, timestamp });
    };

    const clearError = () => {
        setErrorMessage(null);
    };

    return (
        <ErrorContext.Provider value={{ showError }}>
            {children}
            <ErrorNotification errorMessage={errorMessage} clearError={clearError} />
        </ErrorContext.Provider>
    );
};