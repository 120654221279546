import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './components/pages/LoginForm';
import BlankPage from './components/pages/BlankPage';
import { ErrorProvider } from './components/error/ErrorContext';
import Layout from './components/layout/Layout';

function App() {
  return (
    <ErrorProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/blank" element={<BlankPage />} />
          </Routes>
        </Layout>
      </Router>
    </ErrorProvider>
  );
}

export default App;