import errorHandler from "./errorHandler.js";

const REACT_APP_ISCALAR_BACKEND_API_URL = process.env.REACT_APP_ISCALAR_BACKEND_API_URL;

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
};

export async function login(credentials) {

    const body = new URLSearchParams({
        username: credentials.username,
        password: credentials.password,
    });

    try {
        const loginResponse = await fetch(`${REACT_APP_ISCALAR_BACKEND_API_URL}/login`, {
            method: 'POST',
            headers: headers,
            body: body
        });

        return await errorHandler.checkResponse(loginResponse)

    } catch (error) {
        throw error;
    }
}
const exportLogin = { login }
export default exportLogin;