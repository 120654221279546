import React from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  const hideHeaderOnPaths = ["/"];
  const hideFooterOnPaths = ["/"];

  return (
    <div>
      {!hideHeaderOnPaths.includes(location.pathname) && (
        <header>Header</header>
      )}
      <main>{children}</main>
      {!hideFooterOnPaths.includes(location.pathname) && (
        <footer>Footer</footer>
      )}
    </div>
  );
};

export default Layout;
