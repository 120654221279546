import React, { useState, useEffect } from "react";
import "./ErrorNotification.css";

const ERROR_TIMEOUT = 5000;

const ErrorNotification = ({ errorMessage, clearError }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false);
    if (errorMessage?.message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        clearError();
      }, ERROR_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, clearError]);

  return visible ? (
    <div className="error-container">{errorMessage.message}</div>
  ) : null;
};

export default ErrorNotification;
