import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./LoginForm.css";
import logo from "../assets/logo.svg";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import loginApi from "../../api/loginApi.js";
import { useContextError } from "../error/ErrorContext.js";

const REACT_APP_ISCALAR_BACKEND_HOME_URL =
  process.env.REACT_APP_ISCALAR_BACKEND_HOME_URL;

const LoginForm = () => {
  const { showError } = useContextError();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((previousCredentials) => ({
      ...previousCredentials,
      [name]: value,
    }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const loginResponse = await loginApi.login(credentials);
      const token = loginResponse.access_token;
      localStorage.setItem("iscalarAuthToken", token);
      navigate("/blank");
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <div className="login-form">
      <form onSubmit={handleLogin}>
        <img src={logo} className="logo-icon" alt="logo" />
        <div className="login-input-box">
          <input
            type="text"
            placeholder="Usuário"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            required
          />
          <FaUser className="login-user-icon" />
        </div>
        <div className="login-input-box">
          <input
            type="password"
            placeholder="Senha"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
          />
          <FaLock className="login-password-icon" />
        </div>
        <div className="remember-forgot-box">
          <label>
            <input type="checkbox" /> Lembrar-me{" "}
          </label>
          <a href={REACT_APP_ISCALAR_BACKEND_HOME_URL}>Esqueci minha senha</a>
        </div>
        <button type="submit">Entrar</button>
      </form>
      <h1 className="powered-lighter">
        <span className="powered">Powered by </span>
        <span className="powered-highlight">i</span>Scalar
      </h1>
    </div>
  );
};

export default LoginForm;
