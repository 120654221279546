import React from "react";

const BlankPage = () => {
  return (
    <div>
      <h1>Blank Page</h1>
      <p>This is a blank page for demonstration.</p>
    </div>
  );
};

export default BlankPage;
